import { ACCOUNT_URL, API_BASE, PORTAL_URL } from '@/constants/index.js'
import { ref, computed } from 'vue'
import type { IntercomUserData } from '@/types/intercom'

const loggedIn = ref(false)
const isInternal = ref(false)
const accountUrl = ref(ACCOUNT_URL)

const accountId = ref('')
const accountName = ref('')
const accountEmail = ref('')
const accountCreatedTime = ref('')
const accountPhone = ref('')

const intercomUserId = ref('')

const intercomSettings = computed(() => {
	const userData: IntercomUserData = {}
	if (accountEmail.value) {
		userData.email = accountEmail.value
	}
	if (intercomUserId.value) {
		userData.user_id = intercomUserId.value.toString()
	}
	if (accountName.value) {
		userData.name = accountName.value
	}
	if (accountCreatedTime.value) {
		userData.created_at = accountCreatedTime.value
	}
	if (accountPhone.value) {
		userData.phone = accountPhone.value
	}

	userData.hide_default_launcher = false

	return userData
})

export function useAuth () {
	async function getAuth () {
		// TODO tsify
		const authResponse = await $fetch(`${API_BASE}/auth`, { credentials: 'include' })
		if (!authResponse) {
			loggedIn.value = false
			isInternal.value = false
			accountUrl.value = ACCOUNT_URL
			return
		}
		loggedIn.value = !!authResponse.emailId
		isInternal.value = !!authResponse.siteAdmin
		accountUrl.value = isInternal.value ? PORTAL_URL : ACCOUNT_URL

		// Populate user data
		accountId.value = authResponse?.accounts?.[0]?.accountId || ''
		accountName.value = `${authResponse?.contact?.first} ${authResponse?.contact?.last}` || ''
		accountEmail.value = authResponse?.contact?.email || ''
		accountCreatedTime.value = authResponse?.accounts?.[0]?.accountCreatedTime || ''
		accountPhone.value = authResponse?.contact?.phone || ''
		intercomUserId.value = authResponse?.accounts?.[0]?.intercomUserId || accountId.value || ''
	}
	return {
		getAuth,
		loggedIn,
		accountUrl,
		isInternal,
		intercomSettings
	}
}

import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/home/maverixbroadband.com/mx-front/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/maverixbroadband.com/mx-front/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_J2UXS7dnxSvv3PlFW8rqkjoRJICKCPmO_PloFL_AXDo from "/home/maverixbroadband.com/mx-front/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_client_zXJb7bEUBjBgnYU8pF_3D_zUFwWjuxQM9Ck9z4FbB8Y from "/home/maverixbroadband.com/mx-front/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/home/maverixbroadband.com/mx-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/home/maverixbroadband.com/mx-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/maverixbroadband.com/mx-front/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/home/maverixbroadband.com/mx-front/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ from "/home/maverixbroadband.com/mx-front/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import animateOnScroll_EnKTEq_01k4Ofnj6ZgCO6vw5iuTvTkRNhlxoVsOQiGI from "/home/maverixbroadband.com/mx-front/plugins/animateOnScroll.js";
import vue_gtm_client_DuM_72jcib0IK69DdcZBwxLTVQ_jDJkYzF7MLgeU_mY from "/home/maverixbroadband.com/mx-front/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_J2UXS7dnxSvv3PlFW8rqkjoRJICKCPmO_PloFL_AXDo,
  plugin_client_zXJb7bEUBjBgnYU8pF_3D_zUFwWjuxQM9Ck9z4FbB8Y,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ,
  animateOnScroll_EnKTEq_01k4Ofnj6ZgCO6vw5iuTvTkRNhlxoVsOQiGI,
  vue_gtm_client_DuM_72jcib0IK69DdcZBwxLTVQ_jDJkYzF7MLgeU_mY
]

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as tosPMaKi5YabSERZhmQavruBQ7cDV0di3IJ5PmOLSikGqYMeta } from "/home/maverixbroadband.com/mx-front/pages/tos.vue?macro=true";
import { default as aboutPFwCccLaLyU4CIdDOIusZjyekLMEq0eQu0uMMxfl1pcMeta } from "/home/maverixbroadband.com/mx-front/pages/about.vue?macro=true";
import { default as indexfKpnZg60K_Q1DwyW4_45MZc3C22KT0LNrnqeykN789_45foMeta } from "/home/maverixbroadband.com/mx-front/pages/index.vue?macro=true";
import { default as loginlpBSC8uwwzDFOAp2Zinl48WsgSYcIWROLsBFEriNbaUMeta } from "/home/maverixbroadband.com/mx-front/pages/login.vue?macro=true";
import { default as outageNk18sEdxYtQKehm4nEICBTGK5ga4yruU9_45Dtp3vktisMeta } from "/home/maverixbroadband.com/mx-front/pages/outage.vue?macro=true";
import { default as careersTw9NtuPjNphceZ_45o3invIYVOgbOpdD_BcnX9xXH0ojkMeta } from "/home/maverixbroadband.com/mx-front/pages/careers.vue?macro=true";
import { default as contactFf3cJn4c_ALQ0yyQrL855AL_45nsY0aS652Nd_EY792_45MMeta } from "/home/maverixbroadband.com/mx-front/pages/contact.vue?macro=true";
import { default as privacyfHIYqDlSr_450xq1_vOda4oYcapouVeGki7_RQgJP1FukMeta } from "/home/maverixbroadband.com/mx-front/pages/privacy.vue?macro=true";
import { default as get_45fiberQnvIp3VoeaTFGs8wdLVrZctp2l5wtiBABo2Iu6XQjUcMeta } from "/home/maverixbroadband.com/mx-front/pages/get-fiber.vue?macro=true";
import { default as indexHTxB1TfNtQZ_5U3q1vif3BRMjtidcDPM4CFQevN1x2oMeta } from "/home/maverixbroadband.com/mx-front/pages/blog/index.vue?macro=true";
import { default as indexI4d6hlGQehtIG6pD6bUhzGHa2WDORbx60T7054Cf4NkMeta } from "/home/maverixbroadband.com/mx-front/pages/admin/index.vue?macro=true";
import { default as indexobEkc_45vVenygoPb7SqjzsFeurJBMsyzx8zjdAj7EtswMeta } from "/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue?macro=true";
import { default as maintenance2kBol_45rRFzzh5D3lrjHWkENsUQLT0tT2mkZrC2_dzdsMeta } from "/home/maverixbroadband.com/mx-front/pages/maintenance.vue?macro=true";
import { default as indexDdpGDzW4HGKcFmJZM3HnTNTbqjLDfuxw3LXfULANUN4Meta } from "/home/maverixbroadband.com/mx-front/pages/press/index.vue?macro=true";
import { default as find_45addressnrONC94MGOx1gfCPdxDRhkb2_RoSr6UCsDu7nnOBmLEMeta } from "/home/maverixbroadband.com/mx-front/pages/find-address.vue?macro=true";
import { default as _91event_935LBW39eEEZx67AXkJ_ATadPLx_xYy0dzuTvvci8wb3kMeta } from "/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue?macro=true";
import { default as late_45paymentsc_fz7C_45aHkqZT2kFP4zxYuL5rtMdqT4JWfZVaHIkFKEMeta } from "/home/maverixbroadband.com/mx-front/pages/late-payments.vue?macro=true";
import { default as _91article_93IS1NgO0_U629FCLZBcyX8cU09TZCC8PAF6no0G8gjpkMeta } from "/home/maverixbroadband.com/mx-front/pages/blog/[article].vue?macro=true";
import { default as create_45accountqmUqRaZdnTsSmViBZBKlvxDuqHkTBEBumFKaGV3FpBQMeta } from "/home/maverixbroadband.com/mx-front/pages/create-account.vue?macro=true";
import { default as _91linkHash_93fBpvIEIPgT5JbbdOQOXFyqnNO9vXRuM_45ddrN9HZxYBMMeta } from "/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue?macro=true";
import { default as _91page_93av7LBgcpIR6Cm7CiFMLEtZFw6pYDKdOEuhRKwprguGAMeta } from "/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue?macro=true";
import { default as unactivated_45user_45IwMRo5K6anMxA8XTZm4OrGFmc6eEnmowripqcDg44kMeta } from "/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue?macro=true";
import { default as business_45internet7kWKG5hpA_45HIMl8f5dz1XZXYeOkisqXVgqYG0F3fke0Meta } from "/home/maverixbroadband.com/mx-front/pages/business-internet.vue?macro=true";
import { default as already_45registeredPsRt4IWgJlqLC1e_sktCNQgUU0pOW7Q3vuibHFyUqIwMeta } from "/home/maverixbroadband.com/mx-front/pages/already-registered.vue?macro=true";
import { default as indexolDSMir2JEh4Q_45E2_45sn4dX7WmevMgKc6wxszHPzxUCMMeta } from "/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue?macro=true";
import { default as residential_45internet7K3V9JFeSU5QdNF6EfEDB78dK2bYk23G8D8gnlAe8mQMeta } from "/home/maverixbroadband.com/mx-front/pages/residential-internet.vue?macro=true";
import { default as _91area_93s_hCkIZlfH0Vvbfc4i3VPlD_45QYTTjujrtApm7qGTb3AMeta } from "/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue?macro=true";
import { default as acceptable_45use_45policymNL16vs_456Emnd0UqxOtyqaF90a53acRSqc8yKh_zl_kMeta } from "/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue?macro=true";
import { default as indexPNwujxIxctwXwPYX4MndNxDI4WarA7jVA_34JrInMYEMeta } from "/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue?macro=true";
import { default as _91hash_93YL0yMDyltp1AfTJuoK6QYNSwO8oGQpAnGJqvqwkQ5lgMeta } from "/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue?macro=true";
import { default as install_45processvP2jf_gBWwYfyVzJPiIWzGaA5_a9S9IGXaHZKfYf08sMeta } from "/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue?macro=true";
import { default as residential_45services_45agreement7qIxEv2nYuwUa3nMCiQ1JYvP0NBCX_cL2tKM1hSXNCoMeta } from "/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue?macro=true";
export default [
  {
    name: "tos___en",
    path: "/tos",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/tos.vue")
  },
  {
    name: "tos___es",
    path: "/es/tos",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/tos.vue")
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/about.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexfKpnZg60K_Q1DwyW4_45MZc3C22KT0LNrnqeykN789_45foMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexfKpnZg60K_Q1DwyW4_45MZc3C22KT0LNrnqeykN789_45foMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/login.vue")
  },
  {
    name: "outage___en",
    path: "/outage",
    meta: outageNk18sEdxYtQKehm4nEICBTGK5ga4yruU9_45Dtp3vktisMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/outage.vue")
  },
  {
    name: "outage___es",
    path: "/es/outage",
    meta: outageNk18sEdxYtQKehm4nEICBTGK5ga4yruU9_45Dtp3vktisMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/outage.vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/careers.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/careers.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    meta: contactFf3cJn4c_ALQ0yyQrL855AL_45nsY0aS652Nd_EY792_45MMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    meta: contactFf3cJn4c_ALQ0yyQrL855AL_45nsY0aS652Nd_EY792_45MMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/contact.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/privacy.vue")
  },
  {
    name: "privacy___es",
    path: "/es/privacy",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/privacy.vue")
  },
  {
    name: "get-fiber___en",
    path: "/get-fiber",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/get-fiber.vue")
  },
  {
    name: "get-fiber___es",
    path: "/es/get-fiber",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/get-fiber.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/index.vue")
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: indexI4d6hlGQehtIG6pD6bUhzGHa2WDORbx60T7054Cf4NkMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexI4d6hlGQehtIG6pD6bUhzGHa2WDORbx60T7054Cf4NkMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/admin/index.vue")
  },
  {
    name: "kiosk___en",
    path: "/kiosk",
    meta: indexobEkc_45vVenygoPb7SqjzsFeurJBMsyzx8zjdAj7EtswMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue")
  },
  {
    name: "kiosk___es",
    path: "/es/kiosk",
    meta: indexobEkc_45vVenygoPb7SqjzsFeurJBMsyzx8zjdAj7EtswMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue")
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    meta: maintenance2kBol_45rRFzzh5D3lrjHWkENsUQLT0tT2mkZrC2_dzdsMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/maintenance.vue")
  },
  {
    name: "maintenance___es",
    path: "/es/maintenance",
    meta: maintenance2kBol_45rRFzzh5D3lrjHWkENsUQLT0tT2mkZrC2_dzdsMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/maintenance.vue")
  },
  {
    name: "press___en",
    path: "/press",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/press/index.vue")
  },
  {
    name: "press___es",
    path: "/es/press",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/press/index.vue")
  },
  {
    name: "find-address___en",
    path: "/find-address",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/find-address.vue")
  },
  {
    name: "find-address___es",
    path: "/es/find-address",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/find-address.vue")
  },
  {
    name: "kiosk-event___en",
    path: "/kiosk/:event()",
    meta: _91event_935LBW39eEEZx67AXkJ_ATadPLx_xYy0dzuTvvci8wb3kMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue")
  },
  {
    name: "kiosk-event___es",
    path: "/es/kiosk/:event()",
    meta: _91event_935LBW39eEEZx67AXkJ_ATadPLx_xYy0dzuTvvci8wb3kMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue")
  },
  {
    name: "late-payments___en",
    path: "/late-payments",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/late-payments.vue")
  },
  {
    name: "late-payments___es",
    path: "/es/late-payments",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/late-payments.vue")
  },
  {
    name: "blog-article___en",
    path: "/blog/:article()",
    meta: _91article_93IS1NgO0_U629FCLZBcyX8cU09TZCC8PAF6no0G8gjpkMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/[article].vue")
  },
  {
    name: "blog-article___es",
    path: "/es/blog/:article()",
    meta: _91article_93IS1NgO0_U629FCLZBcyX8cU09TZCC8PAF6no0G8gjpkMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/[article].vue")
  },
  {
    name: "create-account___en",
    path: "/create-account",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/create-account.vue")
  },
  {
    name: "create-account___es",
    path: "/es/create-account",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/create-account.vue")
  },
  {
    name: "link-linkHash___en",
    path: "/link/:linkHash()",
    meta: _91linkHash_93fBpvIEIPgT5JbbdOQOXFyqnNO9vXRuM_45ddrN9HZxYBMMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue")
  },
  {
    name: "link-linkHash___es",
    path: "/es/link/:linkHash()",
    meta: _91linkHash_93fBpvIEIPgT5JbbdOQOXFyqnNO9vXRuM_45ddrN9HZxYBMMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue")
  },
  {
    name: "blog-page-page___en",
    path: "/blog/page/:page()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue")
  },
  {
    name: "blog-page-page___es",
    path: "/es/blog/page/:page()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue")
  },
  {
    name: "unactivated-user___en",
    path: "/unactivated-user",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue")
  },
  {
    name: "unactivated-user___es",
    path: "/es/unactivated-user",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue")
  },
  {
    name: "business-internet___en",
    path: "/business-internet",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/business-internet.vue")
  },
  {
    name: "business-internet___es",
    path: "/es/business-internet",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/business-internet.vue")
  },
  {
    name: "already-registered___en",
    path: "/already-registered",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/already-registered.vue")
  },
  {
    name: "already-registered___es",
    path: "/es/already-registered",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/already-registered.vue")
  },
  {
    name: "service-areas___en",
    path: "/service-areas",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue")
  },
  {
    name: "service-areas___es",
    path: "/es/service-areas",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue")
  },
  {
    name: "residential-internet___en",
    path: "/residential-internet",
    meta: residential_45internet7K3V9JFeSU5QdNF6EfEDB78dK2bYk23G8D8gnlAe8mQMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-internet.vue")
  },
  {
    name: "residential-internet___es",
    path: "/es/residential-internet",
    meta: residential_45internet7K3V9JFeSU5QdNF6EfEDB78dK2bYk23G8D8gnlAe8mQMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-internet.vue")
  },
  {
    name: "service-areas-area___en",
    path: "/service-areas/:area()",
    meta: _91area_93s_hCkIZlfH0Vvbfc4i3VPlD_45QYTTjujrtApm7qGTb3AMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue")
  },
  {
    name: "service-areas-area___es",
    path: "/es/service-areas/:area()",
    meta: _91area_93s_hCkIZlfH0Vvbfc4i3VPlD_45QYTTjujrtApm7qGTb3AMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue")
  },
  {
    name: "acceptable-use-policy___en",
    path: "/acceptable-use-policy",
    meta: acceptable_45use_45policymNL16vs_456Emnd0UqxOtyqaF90a53acRSqc8yKh_zl_kMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue")
  },
  {
    name: "acceptable-use-policy___es",
    path: "/es/acceptable-use-policy",
    meta: acceptable_45use_45policymNL16vs_456Emnd0UqxOtyqaF90a53acRSqc8yKh_zl_kMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password___es",
    path: "/es/forgot-password",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-hash___en",
    path: "/forgot-password/:hash()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue")
  },
  {
    name: "forgot-password-hash___es",
    path: "/es/forgot-password/:hash()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue")
  },
  {
    name: "widget-install-process___en",
    path: "/widget/install-process",
    meta: install_45processvP2jf_gBWwYfyVzJPiIWzGaA5_a9S9IGXaHZKfYf08sMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue")
  },
  {
    name: "widget-install-process___es",
    path: "/es/widget/install-process",
    meta: install_45processvP2jf_gBWwYfyVzJPiIWzGaA5_a9S9IGXaHZKfYf08sMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue")
  },
  {
    name: "residential-services-agreement___en",
    path: "/residential-services-agreement",
    meta: residential_45services_45agreement7qIxEv2nYuwUa3nMCiQ1JYvP0NBCX_cL2tKM1hSXNCoMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue")
  },
  {
    name: "residential-services-agreement___es",
    path: "/es/residential-services-agreement",
    meta: residential_45services_45agreement7qIxEv2nYuwUa3nMCiQ1JYvP0NBCX_cL2tKM1hSXNCoMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue")
  }
]
export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"name":"keywords","content":"Maverix Broadband | Fiber Internet"},{"name":"theme-color","content":"#ffffff"},{"name":"robots","content":"index"}],"link":[{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preload","href":"/fonts/Poppins-Regular.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/Poppins-Bold.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/Poppins-SemiBold.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"icon","href":"/favicons/favicon.ico","media":"(prefers-color-scheme: light)"},{"rel":"apple-touch-icon","href":"/favicons/apple-touch-icon.png","sizes":"180x180","media":"(prefers-color-scheme: light)"},{"rel":"mask-icon","href":"/favicons/mask-icon.png","media":"(prefers-color-scheme: light)"},{"rel":"icon","href":"/favicons/dark-mode/favicon.ico","media":"(prefers-color-scheme: dark)"},{"rel":"apple-touch-icon","href":"/favicons/dark-mode/apple-touch-icon.png","sizes":"180x180","media":"(prefers-color-scheme: dark)"},{"rel":"mask-icon","href":"/favicons/dark-mode/mask-icon.png","media":"(prefers-color-scheme: dark)"},{"rel":"dns-prefetch","href":"https://sessions.bugsnag.com"},{"rel":"dns-prefetch","href":"https://maps.googleapis.com"}],"style":[],"script":[{"src":"/scripts/clarity.js","defer":true,"type":"text/javascript"}],"noscript":[],"titleTemplate":"%s | Maverix Broadband"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false

export const purgeCachedData = false